import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'

import { Search as SearchIcon } from './icons'

const HEADER_SCROLL_LIMIT = 100

class Header extends Component {

  ticking = false

  state = {
    scroll: false,
    search: false,
    menuActive: false,
    menuMobile: '',
    input: '',
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = (event) => {
    if (this.ticking) return
    window.requestAnimationFrame(() => {
      let { scroll } = this.state
      //let header = document.querySelector('.split-screen')
      //let height = header ? header.scrollHeight - HEADER_SCROLL_LIMIT : HEADER_SCROLL_LIMIT
      let position = window.scrollY > HEADER_SCROLL_LIMIT
      if (position !== scroll) {
        this.setState({ scroll: position })
      }
      this.ticking = false
    })
    this.ticking = true
  }

  _toggleOffCanvas = () => {
    this.setState({ menuActive: !this.state.menuActive, menuMobile: '' })
  }

  _hideOffCanvas = () => {
    this.setState({ menuActive: false, menuMobile: '' })
  }

  _keyPress = (event) => {
    if (event.keyCode !== 13) {
      return
    }

    this._hideOffCanvas()
    this.setState({ search: false, input: '' })

    navigate(`/search?query=${event.currentTarget.value}`)
  }

  _renderCategories(props) {
    let { categories } = this.props

    return categories.map((el, i) => <li key={i}><Link to={`/${el.node.slug}/`} {...props}>{ el.node.name }</Link></li>)
  }

  _renderMobileMenu(props) {

    switch (this.state.menuMobile) {
      case 'search':
        return (
          <ul>
            <li>
              <input type="text" placeholder="Start Searching" onKeyDown={this._keyPress} />
            </li>
          </ul>
        )
      default:
        return (
          <ul>
            <li><Link to='/' {...props}>Home</Link></li>
            { this._renderCategories(props) }
            <li><Link to='/contact' {...props}>Contact Us</Link></li>
          </ul>
        )
    }
  }

  render() {
    let { menuActive, scroll, search } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let searchClick = () => {
      this.setState({ search: true }, () => {
        this.refs.search.focus()
      })
    }

    let inputProps = {
      ref: 'search',
      type: 'text',
      placeholder: 'Search The Father Hood',
      value: this.state.input,
      onKeyDown: this._keyPress,
      onBlur: () => this.setState({ search: false }),
      onChange: (event) => this.setState({ input: event.currentTarget.value })
    }

    let title = typeof window !== 'undefined' && window.innerWidth < 768 ? 'The Father Hood' : this.props.title

    return (
      <header className={`header ${menuActive && 'header--active'} ${scroll && 'header--scroll'}`} role='banner'>
        <div className='header__inner'>
          <button className='hamburger' onClick={this._toggleOffCanvas}>
            <span className='lines'></span>
          </button>
          <Link to='/' className='header__title' onClick={this._hideOffCanvas}>{ title }</Link>
          <button className='header__mobile-search' onClick={() => this.setState({ menuMobile: 'search', menuActive: !this.state.menuActive })}>
            <SearchIcon color={menuActive ? '#ffffff' : '#000000'} />
          </button>
          <nav role='navigation' className='header__desktop-nav'>
            <ul>
              { this._renderCategories(props) }
            </ul>
          </nav>
          <div className='header__search'>
            { search ?
              <label>
                <input {...inputProps} />
                <SearchIcon color='#000000'  />
              </label>
               :
              <button type='button' onClick={searchClick}>
                <SearchIcon color='#000000'  />
              </button>
            }
            <Link to='/about-us'>Our Story</Link>
          </div>
        </div>

        <div className={`off-canvas ${menuActive && 'off-canvas--active'}`}>
          <div className='off-canvas__nav'>
            { this._renderMobileMenu(props) }
          </div>
        </div>
      </header>
    )
  }
}

export default Header
