import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'
import Transition from '../components/transition'

import '../assets/scss/main.scss'

import favicon from '../assets/images/favicon.ico'
import ogImage from '../assets/images/ogimage.jpg'

const Layout = (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            url
          }
        }
        allWordpressCategory {
          edges {
            node {
              name
              slug
            }
          }
        }
        allWordpressTag {
          edges {
            node {
              name
              slug
            }
          }
        }
      }
    `}
    render={(data) => {

      let { children, location } = props

      return (
        <>
          <Helmet
            defaultTitle={`The Father Hood`}
            title={data.site.siteMetadata.title}
          >
            <html lang="en" />
            <link rel="shortcut icon" href={favicon} type="image/x-icon" />
            <link rel="apple-touch-icon" sizes="180x180" href={ require("../assets/images/apple-touch-icon.png") } />
            <link rel="icon" type="image/png" sizes="32x32" href={ require("../assets/images/favicon-32x32.png") } />
            <link rel="icon" type="image/png" sizes="16x16" href={ require("../assets/images/favicon-16x16.png") } />
            <link rel="mask-icon" href={ require("../assets/images/safari-pinned-tab.svg") } color="#ffffff" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image" content={ogImage} />
            <meta property="og:image:url" content={ogImage} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="The Father Hood - The hood for fathers." />
            <meta property="og:description" content="Helping dads to survive and thrive in the only job that really counts." />
            <meta property="og:site_name" content="The Father Hood" />
            <meta property="og:url" content={`${data.site.siteMetadata.url}${location.pathname}`} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:description" content="Helping dads to survive and thrive in the only job that really counts." />
            <meta name="twitter:title" content="The Father Hood - The hood for fathers." />
          </Helmet>
          <Header
            title={props.pageContext.name || 'The Father Hood'}
            categories={data.allWordpressCategory.edges}
          />
          <Transition location={location}>
            <main className="site-wrapper">
              { children }
            </main>
          </Transition>
          <Footer tags={data.allWordpressTag.edges} />
        </>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
